import { FIREBASE_JS_VERSION } from "../constants/other"
import firebase_config from "../../../../firebase-config"

// TODO update Firebase to new version and use only needed functions to reduce size

export default async ($loadScript) => {
  await $loadScript(`https://www.gstatic.com/firebasejs/${FIREBASE_JS_VERSION}/firebase.js`)

  if (window.firebase.apps.length === 0) {
    window.firebase.initializeApp(firebase_config)

    const auth = window.firebase.auth()
    auth.setPersistence(window.firebase.auth.Auth.Persistence.LOCAL)
  }
}
