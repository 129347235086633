<template>
  <section class="log-in">
    <div class="log-in__panel">
      <h3 v-if="registration && allow_registration" class="marketplace-title">{{ shared_translations.t("login.registration_title") }}</h3>
      <h3 v-else class="marketplace-title">{{ shared_translations.t("login.title") }}</h3>

      <div class="log-in__inputs">
        <p>{{ shared_translations.t("login.email") }}</p>
        <div data-cy="email" class="log-in__inputs--email"><input v-model="email" type="email" name="email" id="email" :placeholder="shared_translations.t('login.type_email')"></div>

        <p>{{ shared_translations.t("login.password") }}</p>
        <div>
          <input data-cy="password" v-model="password" type="password" name="password" id="password" :placeholder="shared_translations.t('login.type_password')">
          <span v-if="!registration" @click="open_password_modal = true">{{ shared_translations.t("login.forgot_password") }}</span>
        </div>

        <p v-if="registration && allow_registration">{{ shared_translations.t("login.password_again") }}</p>
        <div v-if="registration && allow_registration">
          <input v-model="password_again" type="password" name="password" id="password" :placeholder="shared_translations.t('login.type_password')">
        </div>
      </div>
      <div
        data-cy="login-btn"
        class="marketplace-button marketplace-button--basic log-in__button"
        @click="email && password && log_in(undefined)"
      >
        {{ shared_translations.t(`login.${registration ? "register_button" : "login_button" }`) }}
      </div>

      <label v-if="!registration" for="log-in-checkbox" data-cy="login-checkbox" class="log-in__checkbox">
        <input id="log-in-checkbox" type="checkbox" v-model="stay_signed_in" />
        <span>{{ shared_translations.t("login.stay_signed_in") }}</span>
      </label>

      <div
        v-if="error_message || success_message"
        class="log-in__message"
        :class="{ 'log-in__message--error': error_message }"
      >
        {{ shared_translations.te(error_message || success_message) ? shared_translations.t(error_message || success_message) : (error_message || success_message) }}
        <div v-if="error_message.includes('email_not_verified')" class="marketplace-button marketplace-button--basic" @click="resend_verification_email">
          {{ shared_translations.t("login.resend_verification_email") }}
        </div>
      </div>
      <div class="log-in__social-media">
        <p>{{ shared_translations.t("login.or_use_social") }}</p>

        <button
          class="md-button md-raised"
          v-for="name in social_logins"
          :key="name"
          @click="log_in(name, true)"
          :class="`log-in__social-media--${name}`"
        >
          <img :src="`/static/icons/social-media-${name}.svg`">
          {{ shared_translations.t(`login.${name}_signin`) }}
        </button>
      </div>

      <div v-if="allow_registration" @click="registration = !registration" class="log-in__switch">
        {{ shared_translations.t(`login.${registration ? "log_in_message" : "registration_message"}`) }}
      </div>
    </div>

    <div class="log-in__modal" v-if="open_password_modal">
      <div class="log-in__modal-content">
        <h2>{{ shared_translations.t("login.reset_password_email") }}</h2>
        <label for="email">{{ shared_translations.t("login.email") }}</label>
        <input name="email" id="email" autocomplete="off" v-model="forgot_password_email" type="email"/>

        <div class="log-in__modal-buttons">
          <div class="marketplace-button marketplace-button--black-and-white" @click="open_password_modal = false">{{ shared_translations.t("login.cancel") }}</div>
          <div class="marketplace-button marketplace-button--basic" @click="reset_password">{{ shared_translations.t("login.reset_password") }}</div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex"
import init_firebase from "../methods/init_firebase"
import translator from  "../translator"
import { UPDATE_GLOBAL_LOADER, UPDATE_GLOBAL_USER } from "@/store"
import { action_code_settings, min_password_length, USER_STORE } from "../../Admin/constants/others_constants"
import { default_language, SHARED_STORE, social_logins } from "../constants/other"
import { ADMIN_LOGIN, CLIENT_LOGIN, UPDATE_MESSAGE, VERIFY_LOGGED_USER } from "../stores/Shared/constants"
import email_validator from "../../Admin/methods/email_validator"
import { verify_user_token } from "@/applications/Admin/constants/endpoints/firebase";
import { user_stay_signed_in } from "@/data/other_constants";

export default {
  props: {
    allow_registration: {
      type: Boolean,
      required: false,
      default: true
    },
    user_in_admin: {
      type: Boolean,
      required: false,
      default: false
    },
    language: {
      type: String,
      default: default_language
    }
  },
  data() {
    return {
      email: "",
      password: "",
      password_again: "",
      forgot_password_email: "",
      registration: false,
      open_password_modal: false,
      stay_signed_in: window.localStorage.getItem(user_stay_signed_in) === "true",
      shared_translations: { t: () => "", te: () => "" },
      social_logins,
      verify_user_unsubscribe: () => {},
    }
  },
  watch: {
    stay_signed_in: (val) => {
      window.localStorage.setItem(user_stay_signed_in, val)
    }
  },
  computed: {
    ...mapState(SHARED_STORE, [
      "error_message",
      "success_message"
    ]),
    ...mapState(USER_STORE, [
      "user_info"
    ]),
  },
  beforeCreate() {
    if (!window.localStorage.getItem(user_stay_signed_in)) {
      window.localStorage.setItem(user_stay_signed_in, "true")
    }
  },
  async created() {
    this.update_loader(true)
    if (this.$route.query.registration !== undefined) this.registration = true

    this.shared_translations = await translator(true, this.language, "Shared")

    // Needs to be defined like this to enable removeEventListener
    const log_in_on_enter = event => {
      if (event.keyCode === 13) {
        // Cancel the default action, if needed
        event.preventDefault();
        if (this.email && this.password) this.log_in(undefined, this.user_in_admin)
      }
    }
    this.log_in_on_enter = log_in_on_enter

    window.addEventListener("keyup", this.log_in_on_enter, true)

    await init_firebase(this.$loadScript);

    this.verify_user_unsubscribe = await window
      .firebase
      .auth()
      .onAuthStateChanged(this.handle_logged_user);

    this.update_loader(false)
  },
  beforeDestroy() {
    /*
     * For avoiding memory leaks we verify that the user
     * callback is unsubscribed after component destroy
     */
    this.verify_user_unsubscribe();
  },
  methods: {
    ...mapActions(SHARED_STORE, {
      admin_login: ADMIN_LOGIN,
      client_login: CLIENT_LOGIN,
      verify_logged_user: VERIFY_LOGGED_USER,
    }),
    ...mapMutations(SHARED_STORE, {
      update_message: UPDATE_MESSAGE,
    }),
    ...mapMutations({
      update_user: UPDATE_GLOBAL_USER,
      update_loader: UPDATE_GLOBAL_LOADER
    }),
    validate_registration_inputs() {
      if (!this.registration) return true
      if (this.password_again !== this.password) {
        this.update_message({ type: "error", message: this.shared_translations.t("login.un_matched_passwords") })
        return false
      }
      if ([this.password_again, !this.password].some(password => password.length < min_password_length)) {
        this.update_message({ type: "error", message: this.shared_translations.t("login.password_too_short") })
        return false
      }

      return true
    },
    async log_in(provider_name, is_social_login) {
      let user_country
      window.removeEventListener("keyup", this.log_in_on_enter, true)

      if (!is_social_login && this.registration && !this.validate_registration_inputs()) return
      if (this.$route?.query?.lang) {
        if (this.$route?.query?.lang === "sr") user_country = "RS"
        else user_country = this.$route?.query?.lang.toUpperCase()
      }

      await this[`${this.user_in_admin ? "admin" : "client"}_login`]({
        name: provider_name,
        email: this.email,
        is_registration: this.registration,
        password: this.password,
        password_again: this.password_again,
        success_message: this.shared_translations.t("login.registration_success_message"),
        $router: this.$router,
        language: this.language,
        country: user_country
      })

      if (!this.user_in_admin) this.$router.push("/profile")
    },
    /**
     * Reset password functionality
     */
    async reset_password() {
      if (!this.forgot_password_email || !email_validator(this.forgot_password_email)) return

      this.update_loader(true)

      try {
        await window.firebase.auth().sendPasswordResetEmail(this.forgot_password_email)

        this.update_message({ type: "success", message: this.shared_translations.t("login.messages.successful_password_reset") })
        this.forgot_password_email = ""
      } catch ({ message }) {
        this.update_message({ type: "error", message: message })
      }
      this.update_loader(false)
      this.open_password_modal = false
    },
    /**
     * Verification email resend functionality
     */
    async resend_verification_email() {
      this.update_loader(true)

      try {
        await init_firebase(this.$loadScript)
        await window.firebase.auth().signInWithEmailAndPassword(this.email, this.password)
        await window.firebase.auth().currentUser.sendEmailVerification(action_code_settings())

        this.update_message({ type: "success", message: this.shared_translations.t("login.messages.email_verification_resend") })
        this.forgot_password_email = ""
      } catch ({ message }) {
        this.update_message({ type: "error", message: message })
      } finally {
        this.update_loader(false)
      }

      this.update_message({ type: "error", message: "" })
    },
    async handle_logged_user() {
      if (this.registration) return

      const user_will_stay_signed = window.localStorage.getItem(user_stay_signed_in) === "true"

      if (!user_will_stay_signed) return window.firebase.auth().signOut()

      this.update_loader(true)
      const current_user_token = await window.firebase.auth().currentUser?.getIdToken()
      const user_id = this.user_info?.uid || this.user_info?.id || null

      if (current_user_token && !user_id) {
        try {
          const user_data = await verify_user_token({
            user_token: current_user_token,
          });

          await this.verify_logged_user({ user_info: user_data.data, $router: this.$router })

          if (!this.user_in_admin) this.$router.push("/profile")
        } catch (e) {
          window.firebase.auth().signOut()
        } finally {
          this.update_loader(false)
        }
      }

      this.update_loader(false)
    }
  }
}
</script>

<style lang="scss">
  @use "../../../styles/_global-constants" as *;
  @use "../../../styles/_marketplace-constants" as *;
  @use "../../../styles/marketplace" as *;

  .log-in {
    position: relative;
    padding: $spacing--large $spacing--small;

    @media (max-width: $tablet--small) {
      padding: 0;
    }

    &__modal {
      position: fixed;
      background-color: $shadow-color;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1000;
      transition: all 0.3s;

      &-content {
        width: 100%;
        max-width: 400px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: $spacing--small;
        background: $pure-white;
        border-radius: $border-radius;

        input {
          padding-left: 0 !important;
        }
      }

      &-buttons {
        margin-top: $spacing--small;
        text-align: right;

        div:first-child {
          margin-right: $spacing--small;
        }
      }
    }

    &__panel {
      width: 100%;
      max-width: 400px;
      margin: auto;
      padding: $spacing--medium;
      text-align: center;
      box-shadow: 0 0 9px $shadow-color;
      border-radius: $border-radius;

      @media (max-width: $tablet--small) {
        padding: $spacing--medium $spacing--small;
        box-shadow: none;
      }
    }

    h3 {
      margin: 0 0 $spacing--large;
    }

    &__message {
      padding: $spacing--small;
      text-align: center;
      color: $pure-white;
      border-radius: $border-radius;
      background-color: $green-color;

      &--error {
        background-color: $alert-color;
      }

      .marketplace-button {
        margin-top: $spacing--small;
      }
    }

    &__button {
      display: block !important;
      margin: $spacing--large auto $spacing--small;
      width: 100% !important;
      max-width: 200px;
      font-size: 15px !important;
      line-height: 42px !important;
      height: 42px !important;
    }

    &__inputs {
      text-align: left;

      p {
        margin: 0 0 $spacing--tiny;
        color: $grey--dark;
        font-size: $font-size--tiny;
        font-weight: 600;
      }

      div {
        position: relative;
        margin-bottom: $spacing--medium;

        &::before {
          position: absolute;
          left: 5px;
          top: 8px;
          width: $tiny-icon;
          height: $tiny-icon;
          background-image: url("/static/icons/lock.svg");
          background-size: $tiny-icon $tiny-icon;
          content: "";
        }
      }

      &--email {
        &::before {
          background-image: url("/static/icons/account-icon.svg") !important;
        }
      }

      span {
        float: right;
        font-weight: 600;
        font-size: $font-size--tiny;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &__social-media {
      p {
        font-size: $font-size--small;
        margin: $spacing--medium 0 $spacing--tiny;
        font-weight: 500;
      }

      img {
        width: 18px;
        height: 18px;
        margin: 0 15px 0 0;
      }

      .md-button {
        position: relative;
        display: flex;
        align-items: center;
        outline: none;
        background: transparent;
        border: 0;
        transition: .4s cubic-bezier(.4,0,.2,1);
        font-family: inherit;
        text-transform: uppercase;
        padding: 0 8px !important;
        box-shadow:
          0 3px 1px -2px rgba(0,0,0,0.2),
          0 2px 2px 0 rgba(0,0,0,0.14),
          0 1px 5px 0 rgba(0,0,0,0.12);
        line-height: 36px;
        margin: 0 auto !important;
        border-radius: 2px;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;

        &:before {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 1;
          opacity: 0;
          transition: .4s cubic-bezier(.4,0,.2,1);
          will-change: background-color,opacity;
          content: " ";
        }

        &:hover:before {
          opacity: .12;
          background-color: currentColor;
        }

        .md-ripple {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0;
        }
        &:last-child {
          margin-top: 10px !important;
        }
      }

      .md-ripple {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 4;
        overflow: hidden;
      }
    }

    &__switch {
      margin-top: $spacing--small;
      text-align: center;
      font-size: $font-size--small;
      text-decoration: underline;
      cursor: pointer;
    }

    input {
      display: block;
      width: 100%;
      margin: 0 0 $spacing--tiny;
      border: none;
      border-bottom: 1px solid $grey--light;
      color: $grey--dark;
      line-height: 30px;
      padding-left: $spacing--medium;
      font-size: $font-size--small;
      background-color: transparent;
      transition: $default-transition;

      &:hover, &:active, &:focus {
        border-color: $grey;
        outline: none;
      }

      &::placeholder {
        text-align: left;
        color: $grey;
      }
    }

    &__checkbox {
      display: block;
      text-align: center;

      input[type=checkbox] {
        display: initial;
        border: none;
        line-height: normal;
        padding: 0;
        position: relative;
        width: 1em;
        margin-right: $spacing--tiny;
      }
    }
  }
</style>
